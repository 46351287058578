import { Modal } from "antd";
import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";
import i18n from "i18n/init";
import { postCxmOktaAuthLoginOut } from "../../api/services";
import styles from "./styles.module.scss";
import i18next from "i18next";
export const isProdEnv = !!(window.location.host.split(":")[0] !== "localhost");

/**
 * 因客户需求 用户可以在地址 host 后面加国家代码 区分国家地址 /fr /jp /us ....
 * 如：https://xxxx/fr/login 或 https://xxxx/jp/login
 * 但是同一套代码 同一套服务器 只能前台路由Router 加 basename 来实现
 * 动态获取浏览器地址来添加 basename, 默认 /fr
 **/

export const getBasename = () => {
  const href = window.location.href;
  const reg = /http[s]?:\/\/[^\/]+\/([a-z]{2,4})\/*/i;
  const countryCode = href.match(reg) && href.match(reg)[1];

  let basename = "/fr";
  if (
    [
      "fr",
      "jp",
      "it",
      "au",
      "nz",
      "nl",
      "gb",
      "be",
      "de",
      "ch",
      "at",
      "es",
      "us",
      "br",
      "kr",
      "mx",
      "tr",
      "dk",
      "pt",
      "pr",
      "ar",
      "bg",
      "ca",
      "cl",
      "cn",
      "co",
      "cz",
      "ee",
      "fi",
      "gr",
      "hk",
      "hr",
      "hu",
      "id",
      "ie",
      "il",
      "in",
      "lt",
      "lv",
      "my",
      "no",
      "ph",
      "pl",
      "re",
      "ro",
      "ru",
      "se",
      "sg",
      "sk",
      "sl",
      "th",
      "tw",
      "ua",
      "za",
      "mq",
      "gp",
    ].includes(countryCode)
  ) {
    basename = `/${countryCode}`;
  }
  sessionStorage.setItem("basename", basename);
  return basename;
};

export const logOut = () => {
  const basename = sessionStorage.getItem("basename");
  postCxmOktaAuthLoginOut().then(() => {
    window.location.pathname = `${basename}/logout`;
  });
};

export const isMobileApp = () => {
  const devices = navigator.userAgent.toLowerCase();
  return /iphone/.test(devices) || /android/.test(devices);
};

export const OpenMessageFunc = ({ success, message, handleAfterClose }) => {
  const config = {
    icon: null,
    centered: true,
    maskClosable: true,
    closable: false,
    okButtonProps: { className: styles["ok-button"] },
    afterClose: () => {
      if (handleAfterClose) {
        handleAfterClose();
      }
    },
  };
  if (isMobileApp()) {
    Object.assign(config, {
      className: styles["rc-mobile-modal"],
      content: (
        <div className={styles.container}>
          <div className={styles.success}>
            {success ? i18n.t("common:success") : i18n.t("common:error")}
          </div>
          <div className={styles.message}>{message}</div>
        </div>
      ),
      okButtonProps: { className: styles["ok-button"] },
      cancelButtonProps: {
        type: "link",
        size: "large",
        className: styles["cancel-button"],
      },
      cancelText: i18n.t("common:confirm"),
      width: "80%",
    });
  } else {
    Object.assign(config, {
      className: styles["rc-desktop-modal"],
      title: i18next.t("common:prompt"),
      closable: true,
      width: 360,
      content: (
        <div className={styles.container}>
          {success ? (
            <CheckCircleFilled className={styles.check} />
          ) : (
            <CloseCircleFilled className={styles.close} />
          )}
          <span
            className={styles.html}
            dangerouslySetInnerHTML={{ __html: message }}
          />
        </div>
      ),
      okButtonProps: { className: styles["ok-button"] },
      cancelButtonProps: { className: styles["ok-button"] },
    });
  }
  Modal.confirm(config);
};

class OpenUniqueMessage {
  oktaOpen = false;
  openMessage({ success, message, handleAfterClose, isOktaError }) {
    if (this.oktaOpen) {
      return;
    }
    if (isOktaError) {
      Modal.destroyAll?.();
    }
    OpenMessageFunc({ success, message, handleAfterClose });
    this.oktaOpen = !!isOktaError;
  }
}
const OpenMessageInstance = new OpenUniqueMessage();
export const OpenMessage = (args) => OpenMessageInstance.openMessage(args);

export const ConfirmAction = (message, handler) => {
  Modal.confirm({
    title: i18n.t("common:confirm_header"),
    content: message || i18n.t("common:confirm_message"),
    okText: i18n.t("common:confirm"),
    cancelText: i18n.t("common:cancel"),
    onOk() {
      if (handler) {
        handler();
      }
    },
  });
};

export function sleep(time) {
  return new Promise((resolve) => setTimeout(resolve, time));
}

/**
 * 以引用的特性构建tree
 */
export const array2Tree = (arr, opt = {}) => {
  if (!Array.isArray(arr) && !arr.length) {
    return [];
  }
  const DEFAULT_OPT = {
    identifyKey: "id",
    parentKey: "pid",
    childrenKey: "children",
  };
  opt = Object.assign({}, DEFAULT_OPT, opt);
  const root = {
    [opt.childrenKey]: [],
  };
  arr.forEach((item, _, arr) => {
    const p = arr.find((it) => it[opt.identifyKey] === item[opt.parentKey]);
    if (!p) {
      root[opt.childrenKey][root[opt.childrenKey].length] = item;
    } else {
      p[opt.childrenKey] = p[opt.childrenKey] || [];
      p[opt.childrenKey][p[opt.childrenKey].length] = item;
    }
  });
  return root[opt.childrenKey];
};

export const getThousandsFormat = (num) => {
  if (typeof num !== "number") return num;
  if (num === 0) return num;
  let [integer, decimal] = String.prototype.split.call(num, ".");
  integer = (integer || 0).toString();
  let result = "";
  while (integer.length > 3) {
    result = "," + integer.slice(-3) + result;
    integer = integer.slice(0, integer.length - 3);
  }
  if (integer) {
    result = integer + result;
  }
  return `${result}${decimal ? "." + decimal : ""}`;
};

export const getOrdinal = (n) => {
  if (n === "" || n === undefined || n === null) {
    return "";
  }
  let ord = ["st", "nd", "rd"];
  let exceptions = [11, 12, 13];
  let nth =
    ord[(n % 10) - 1] === undefined || exceptions.includes(n % 100)
      ? "th"
      : ord[(n % 10) - 1];
  return n + nth;
};
