export const Enum = {
  campaignStatus: {
    activated: { label: "Activated", value: 0 },
    draft: { label: "Drafted", value: 1 },
    scheduled: { label: "Scheduled", value: 2 },
    paused: { label: "Paused", value: 3 },
    ended: { label: "Ended", value: 4 },
    resume: { label: "Resume", value: 5 },
  },
  menuTypes: {
    menu: { label: "Menu", value: 1 },
    button: { label: "Function", value: 2 },
    authority: { label: "Authority", value: 3 },
  },
  countryCode: {
    "/de": {
      label: "Germany",
      value: "DE",
      icon: "icon-DE",
      store: process.env.REACT_APP_STORE_EU,
    },
    "/be": {
      label: "Belgium",
      value: "BE",
      icon: "icon-BE",
      store: process.env.REACT_APP_STORE_EU,
    },
    "/ch": {
      label: "Switzerland",
      value: "CH",
      icon: "icon-CH",
      store: process.env.REACT_APP_STORE_EU,
    },
    "/es": {
      label: "Spain",
      value: "ES",
      icon: "icon-ES",
      store: process.env.REACT_APP_STORE_EU,
    },
    "/at": {
      label: "Austria",
      value: "AT",
      icon: "icon-AT",
      store: process.env.REACT_APP_STORE_EU,
    },
    "/us": {
      label: "United States",
      value: "US",
      icon: "icon-US",
      store: process.env.REACT_APP_STORE,
    },
    "/br": {
      label: "Brazil",
      value: "BR",
      icon: "icon-BR",
      store: process.env.REACT_APP_STORE,
    },
    "/mx": {
      label: "Mexico",
      value: "MX",
      icon: "icon-MX",
      store: process.env.REACT_APP_STORE,
    },
    "/kr": {
      label: "South Korea",
      value: "KR",
      icon: "icon-KR",
      store: process.env.REACT_APP_STORE,
    },
    "/tr": {
      label: "Turkey",
      value: "TR",
      icon: "icon-TR",
      store: process.env.REACT_APP_STORE_EU,
    },
    "/fr": {
      label: "France",
      value: "FR",
      icon: "icon-FR",
      store: process.env.REACT_APP_STORE_EU,
    },
    "/jp": {
      label: "Japan",
      value: "JP",
      icon: "icon-JP",
      store: process.env.REACT_APP_STORE_JP,
    },
    "/it": {
      label: "Italy",
      value: "IT",
      icon: "icon-IT",
      store: process.env.REACT_APP_STORE_EU,
    },
    "/au": {
      label: "Australia",
      value: "AU",
      icon: "icon-AU",
      store: process.env.REACT_APP_STORE,
    },
    "/nl": {
      label: "Netherlands",
      value: "NL",
      icon: "icon-NL",
      store: process.env.REACT_APP_STORE,
    },
    "/nz": {
      label: "New-Sealand",
      value: "NZ",
      icon: "icon-NZ",
      store: process.env.REACT_APP_STORE,
    },
    "/gb": {
      label: "Great-Britain",
      value: "GB",
      icon: "icon-gb",
      store: process.env.REACT_APP_STORE_EU,
    },
    "/dk": {
      label: "Denmark",
      value: "DK",
      icon: "icon-dk",
      store: process.env.REACT_APP_STORE_EU,
    },
    "/pt": {
      label: "Portugal",
      value: "PT",
      icon: "icon-pt",
      store: process.env.REACT_APP_STORE_EU,
    },
    "/pr": {
      label: "Puerto Rico",
      value: "PR",
      icon: "icon-",
      store: process.env.REACT_APP_STORE,
    },
    "/ar": {
      label: "Argentina",
      value: "AR",
      icon: "icon-ar",
      store: process.env.REACT_APP_STORE,
    },
    "/bg": {
      label: "Bulgaria",
      value: "BG",
      icon: "icon-bg",
      store: process.env.REACT_APP_STORE,
    },
    "/ca": {
      label: "Canada",
      value: "CA",
      icon: "icon-ca",
      store: process.env.REACT_APP_STORE,
    },
    "/cl": {
      label: "Chile",
      value: "CL",
      icon: "icon-cl",
      store: process.env.REACT_APP_STORE,
    },
    "/cn": {
      label: "China",
      value: "CN",
      icon: "icon-cn",
      store: process.env.REACT_APP_STORE,
    },
    "/co": {
      label: "Colombia",
      value: "CO",
      icon: "icon-co",
      store: process.env.REACT_APP_STORE,
    },
    "/cz": {
      label: "Czech Rep.",
      value: "CZ",
      icon: "icon-cz",
      store: process.env.REACT_APP_STORE,
    },
    "/ee": {
      label: "Estonia",
      value: "EE",
      icon: "icon-ee",
      store: process.env.REACT_APP_STORE,
    },
    "/fi": {
      label: "Finland",
      value: "FI",
      icon: "icon-fi",
      store: process.env.REACT_APP_STORE,
    },
    "/gr": {
      label: "Greece",
      value: "GR",
      icon: "icon-gr",
      store: process.env.REACT_APP_STORE,
    },
    "/hk": {
      label: "Hong Kong",
      value: "HK",
      icon: "icon-hk",
      store: process.env.REACT_APP_STORE,
    },
    "/hr": {
      label: "Croatia",
      value: "HR",
      icon: "icon-hr",
      store: process.env.REACT_APP_STORE,
    },
    "/hu": {
      label: "Hungary",
      value: "HU",
      icon: "icon-hu",
      store: process.env.REACT_APP_STORE,
    },
    "/id": {
      label: "Indonesia",
      value: "ID",
      icon: "icon-id",
      store: process.env.REACT_APP_STORE,
    },
    "/ie": {
      label: "Ireland",
      value: "IE",
      icon: "icon-ie",
      store: process.env.REACT_APP_STORE,
    },
    "/il": {
      label: "Israel",
      value: "IL",
      icon: "icon-il",
      store: process.env.REACT_APP_STORE,
    },
    "/in": {
      label: "India",
      value: "IN",
      icon: "icon-in",
      store: process.env.REACT_APP_STORE,
    },
    "/lt": {
      label: "Lithuania",
      value: "LT",
      icon: "icon-lt",
      store: process.env.REACT_APP_STORE,
    },
    "/lv": {
      label: "Latvia",
      value: "LV",
      icon: "icon-lv",
      store: process.env.REACT_APP_STORE,
    },
    "/my": {
      label: "Malaysia",
      value: "MY",
      icon: "icon-my",
      store: process.env.REACT_APP_STORE,
    },
    "/no": {
      label: "Norway",
      value: "NO",
      icon: "icon-no",
      store: process.env.REACT_APP_STORE,
    },
    "/ph": {
      label: "Philippines",
      value: "PH",
      icon: "icon-ph",
      store: process.env.REACT_APP_STORE,
    },
    "/pl": {
      label: "Poland",
      value: "PL",
      icon: "icon-pl",
      store: process.env.REACT_APP_STORE,
    },
    "/re": {
      label: "Reunion",
      value: "RE",
      icon: "icon-re",
      store: process.env.REACT_APP_STORE,
    },
    "/ro": {
      label: "Romania",
      value: "RO",
      icon: "icon-ro",
      store: process.env.REACT_APP_STORE,
    },
    "/ru": {
      label: "Russia",
      value: "RU",
      icon: "icon-ru",
      store: process.env.REACT_APP_STORE,
    },
    "/se": {
      label: "Sweden",
      value: "SE",
      icon: "icon-se",
      store: process.env.REACT_APP_STORE,
    },
    "/sg": {
      label: "Singapour",
      value: "SG",
      icon: "icon-sg",
      store: process.env.REACT_APP_STORE,
    },
    "/sk": {
      label: "Slovakia",
      value: "SK",
      icon: "icon-sk",
      store: process.env.REACT_APP_STORE,
    },
    "/sl": {
      label: "Slovenia",
      value: "SL",
      icon: "icon-sl",
      store: process.env.REACT_APP_STORE,
    },
    "/th": {
      label: "Thailand",
      value: "TH",
      icon: "icon-th",
      store: process.env.REACT_APP_STORE,
    },
    "/tw": {
      label: "Taiwan",
      value: "TW",
      icon: "icon-tw",
      store: process.env.REACT_APP_STORE,
    },
    "/ua": {
      label: "Ukraine",
      value: "UA",
      icon: "icon-ua",
      store: process.env.REACT_APP_STORE,
    },
    "/za": {
      label: "South Africa",
      value: "ZA",
      icon: "icon-za",
      store: process.env.REACT_APP_STORE,
    },
    "/mq": {
      label: "Martinique",
      value: "MQ",
      icon: "icon-mq",
      store: process.env.REACT_APP_STORE,
    },
    "/gp": {
      label: "Guadeloupe",
      value: "GP",
      icon: "icon-gp",
      store: process.env.REACT_APP_STORE,
    },
  },
  fragmentTypes: {
    Seasonal: { label: "Seasonal", value: "Seasonal" },
    Promo: { label: "Promo", value: "Promo" },
  },
  sharedContentStatus: {
    unplanned: { label: "Unplanned", value: "Unplanned" },
    planned: { label: "Planned", value: "Planned" },
    active: { label: "Active", value: "Active" },
    over: { label: "Over", value: "Over" },
  },
  purchasePlace: {
    pos: { label: "Pos", value: "pos" },
    vet: { label: "Vet", value: "vet" },
  },
  partnerType: {
    clinic: { label: "Clinic", value: "clinic" },
    breeder: { label: "Breeder", value: "breeder" },
    shelter: { label: "Shelter", value: "shelter" },
    pension: { label: "Pension", value: "pension" },
    pharma: { label: "Pharma", value: "pharma" },
    club: { label: "Club", value: "club" },
    groomer: { label: "Groomer", value: "groomer" },
    pos: { label: "Pos", value: "pos" },
    web: { label: "Web", value: "web" },
    other: { label: "Other", value: "other" },
  },
  dealStatus: {
    active: {
      label: "Active",
      value: "ACTIVE",
    },
    paused: {
      label: "Paused",
      value: "PAUSED",
    },
    draft: {
      label: "Draft",
      value: "DRAFT",
    },
    scheduled: {
      label: "Scheduled",
      value: "SCHEDULED",
    },
    inactive: {
      label: "Inactive",
      value: "INACTIVE",
    },
    finished: {
      label: "Finished",
      value: "FINISHED",
    },
  },
  partnerCodeStatus: {
    available: {
      label: "Available",
      value: "AVAILABLE",
    },
    booked: {
      label: "Booked",
      value: "BOOKED",
    },
    used: {
      label: "Used",
      value: "USED",
    },
    removed: {
      label: "Removed",
      value: "REMOVED",
    },
  },
  couponStatus: {
    available: {
      label: "AVAILABLE",
      value: "AVAILABLE",
    },
    used: {
      label: "USED",
      value: "USED",
    },
    booked: {
      label: "BOOKED",
      value: "BOOKED",
    },
  },
};

export type keyType =
  | "campaignStatus"
  | "menuTypes"
  | "countryCode"
  | "fragmentTypes"
  | "sharedContentStatus"
  | "purchasePlace"
  | "partnerType"
  | "dealStatus"
  | "couponStatus";

export const getEnumList = (type: keyType) => {
  const enumType = Enum[type];
  if (Object.prototype.toString.call(enumType) === "[object Object]") {
    return Object.values(enumType);
  } else {
    return [];
  }
};

export const getEnumItem = (type: keyType, value: any) => {
  const item = getEnumList(type).find((enumList) => enumList?.value === value);
  return item || { label: "", value: value };
};

export const getEnumLabel = (type: keyType, value: any) => {
  const item = getEnumItem(type, value);
  return item?.label || value;
};

export enum Roles {
  Admin,
  Editor,
  Vet,
  VetAdmin,
  VetAssist,
  VetMuf,
}

export enum accountTypeEnum {
  test = 0,
  prod = 1,
  internal = 2,
}

export enum genderEnum {
  Female = 0,
  Male = 1,
  Other = 2,
}
